import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const Thanks = () => (
  <Layout>
    <SEO title='Thanks' />
    <h1>Thanks for send a note</h1>
    {/* <p>You just hit a route that doesn&#39;t exist... the sadness.</p> */}
  </Layout>
)

export default Thanks
